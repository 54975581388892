import domReady from '@roots/sage/client/dom-ready';
import 'bootstrap';
import Swiper from 'swiper';

/**
 * Application entrypoint
 */
domReady(async () => {
  const galleries = document.querySelectorAll('.swiper-mode-1');
  const partners = document.querySelectorAll('.swiper-mode-2');

  galleries.forEach(gallery => {
    const arrow = gallery.querySelector('.swiper-next');
    const slider = new Swiper(gallery, {
      speed: 400,
      spaceBetween: 30,
      slidesPerView: 2,
      loop: true,
      navigation: {
        nextEl: '.swiper-wrapper-next',
      }
    });

    arrow.addEventListener('click', () => {
      slider.slideNext(500)
    });

    setInterval(() => {
      slider.slideNext();
    }, 3000)
  })

 partners.forEach(partner => {
  const arrow = partner.querySelector('.swiper-next');
  const slider = new Swiper(partner, {
    spaceBetween: 30,
    slidesPerView: 5,
    loop: true,
    autoplay: true,
    slidesPerView: 2,
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 5,
      }
    }
  });

  arrow.addEventListener('click', () => {
    slider.slideNext(500)
  });

  setInterval(() => {
    slider.slideNext();
  }, 3000)

 })

  const advancedCooks = document.querySelectorAll('.our-cook-advanced');

  advancedCooks.forEach(cook => {
    const desc = cook.querySelector('.our-cook-advanced-description');
    const content = cook.querySelector('.our-cook-advanced-content');

    const descHeight = desc.clientHeight;

    if (document.body.clientWidth >= 768)
      desc.style.height = 0;

    window.addEventListener('resize', () => {
      if (document.body.clientWidth >= 768)
        desc.style.height = 0;
      else
        desc.style.height = 'initial';
    });

    cook.addEventListener('mouseenter', () => {
      if (document.body.clientWidth >= 768) {
        desc.style.height = `${descHeight}px`;
        content.style.marginTop = `-${descHeight}px`;
      }
    });

    cook.addEventListener('mouseleave', () => {
      if (document.body.clientWidth >= 768) {
        desc.style.height = 0;
        content.style.marginTop = 0;
      }
    });
  });

  const addToCartButtons = document.querySelectorAll('.add-to-card-button');

  addToCartButtons.forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault();
      fetch(`/${button.getAttribute('href')}`);
    })
  })
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
